import { IconName } from '@app/ui-kit';

export interface SocialLink {
  name: string;
  icon: IconName;
  href: string;
  external: true;
}

export const DISCORD_LINK = 'https://discord.gg/wormfare';

export const SOCIALS: SocialLink[] = [
  {
    name: 'Discord',
    icon: 'discord',
    href: DISCORD_LINK,
    external: true,
  },
  {
    name: 'X / Twitter',
    icon: 'twitter',
    href: 'https://twitter.com/wormfare',
    external: true,
  },
  {
    name: 'Instagram',
    icon: 'instagram',
    href: 'https://instagram.com/wormfare',
    external: true,
  },
  {
    name: 'Telegram',
    icon: 'telegram',
    href: 'https://t.me/wormfare',
    external: true,
  },
];
