import { HeaderItem } from '@app/components/Header/HeaderLink/HeaderLink';

export const HEADER_LINKS: HeaderItem[] = [
  { label: 'WOFR', href: '/', disabled: true },
  { label: 'My Vesting', href: '/my-vesting', disabled: true },
  { label: 'Buy', href: '/purchase' },
  { label: 'Referral', href: '/referral-program' },
  { label: 'My Assets', href: '/assets/loot' },
  { label: 'Slap!', href: '/slap', image: '/media/slap.png' },
  { label: 'Gifts!', href: '/games/choose-box', image: '/media/gift.png' },
];
