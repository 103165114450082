import { ButtonMain } from '@app/ui-kit';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useDisconnect } from 'wagmi';
import { AnimatePresence, m } from 'framer-motion';

import UserAccount from '@app/components/Header/UserAccount';

import { useAuthContext } from '@app/context/AuthContextProvider';
import { FRAMER_OPACITY_TRANSITION } from '@app/constants/framerTransitions';

import s from './Wallet.module.scss';

const Wallet: React.FC = () => {
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { isLoading, isAuth } = useAuthContext();

  const handleOpenWalletModal = () => {
    disconnect();
    setTimeout(open, 500);
  };

  return (
    <AnimatePresence mode="wait">
      {!isAuth && !isLoading && (
        <m.div
          key={isLoading.toString() + 'connectM'}
          className={s.walletMobile}
          {...FRAMER_OPACITY_TRANSITION}
        >
          <ButtonMain label="Connect" onClick={handleOpenWalletModal} />
        </m.div>
      )}
      {!isAuth && !isLoading && (
        <m.div
          key={isLoading.toString() + 'connectD'}
          className={s.walletDesktop}
          {...FRAMER_OPACITY_TRANSITION}
        >
          <ButtonMain label="Connect Wallet" onClick={handleOpenWalletModal} />
        </m.div>
      )}
      {isAuth && !isLoading && (
        <m.div
          key={isLoading.toString() + 'accountM'}
          className={s.walletMobile}
          {...FRAMER_OPACITY_TRANSITION}
        >
          <UserAccount />
        </m.div>
      )}
      {isAuth && !isLoading && (
        <m.div
          key={isLoading.toString() + 'accountD'}
          className={s.walletDesktop}
          {...FRAMER_OPACITY_TRANSITION}
        >
          <UserAccount />
        </m.div>
      )}
    </AnimatePresence>
  );
};

export default Wallet;
