import React from 'react';
import cn from 'classnames';

import { Icon, IconName } from '@app/ui-kit';

import s from './FooterLink.module.scss';

interface FooterLinkProps {
  iconName: IconName;
  label: string;
  className?: string;
  href?: string;
  as?: 'button' | 'a';
  onClick?: () => void;
}

const FooterLink = ({
  iconName,
  label,
  href,
  as = 'a',
  className,
  onClick,
}: FooterLinkProps) => {
  const classNames = cn(s.root, className);

  const contents = (
    <>
      <Icon name={iconName} className={s.icon} />
      <span>{label}</span>
    </>
  );

  if (as === 'a' && href) {
    return (
      <a className={classNames} href={href} target="_blank" rel="noreferrer">
        {contents}
      </a>
    );
  }

  return (
    <button className={classNames} onClick={onClick} type="button">
      {contents}
    </button>
  );
};

export default FooterLink;
