import React from 'react';
import cn from 'classnames';
import Image from 'next/image';

import { useAuthContext } from '@app/context/AuthContextProvider';
import { AccountSettingsMenu } from '@app/components/AccountSettingsMenu';
import Balance, {
  BalanceType,
  BalanceVariant,
} from '@app/components/Header/Balance';
import { formatHash } from '@app/utils';
import { useUserContext } from '@app/context/UserContextProvider';
import { Icon } from '@app/ui-kit';

import s from './UserAccount.module.scss';

interface UserAccountProps {
  className?: string;
}

const UserAccount = ({ className }: UserAccountProps) => {
  const { address, isLoading, balanceWOFR, balanceUSDT, balanceMATIC } =
    useUserContext();
  const { logout } = useAuthContext();

  const copyAddress = () => {
    if (navigator.clipboard && address) {
      navigator.clipboard.writeText(address);
    }
  };

  return (
    <div className={cn(s.root, className)}>
      <Balance
        type={BalanceType.wofr}
        amount={balanceWOFR}
        isLoading={isLoading}
        className={s.mainBalance}
      />
      <AccountSettingsMenu />
    </div>
  );
};

export default UserAccount;
