import { IconName } from '@app/ui-kit';
import { WEBSITE_LINK, WHITEPAPER_LINK } from './links';

type FooterLink = {
  label: string;
  iconName: IconName;
  href: string;
};

export const footerLinks: FooterLink[] = [
  { label: 'Website', iconName: 'website', href: WEBSITE_LINK },
  { label: 'Whitepaper', iconName: 'document', href: WHITEPAPER_LINK },
];
