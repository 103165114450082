'use client';

import React from 'react';
import Link from 'next/link';
import cn from 'classnames';
import Image from 'next/image';
import { usePathname } from 'next/navigation';

import { HEADER_LINKS } from '@app/constants/headerLinks';
import BurgerButton from '@app/components/BurgerButton';
import { useHeaderControls } from '@app/hooks';
import HeaderLink from '@app/components/Header/HeaderLink';
import NavMobile from '@app/components/Header/NavMobile';
import { Wallet } from './Wallet';

import s from './Header.module.scss';

const Header = () => {
  const pathname = usePathname();
  const { headerRef, isOpen, isAuthLoading, toggleHeader, closeHeader } =
    useHeaderControls();

  return (
    <header
      className={cn(s.root, {
        [s.withBackground]: !pathname.includes('/games/'),
      })}
      ref={headerRef}
    >
      <div className={s.container}>
        <div className={s.leftWrapper}>
          <Link href="https://wormfare.com/" className={s.logoWrapper}>
            <Image
              src={'/media/header-logo.svg'}
              width={122}
              height={98}
              alt={'Wormfare'}
              className={s.logo}
              priority
            />
            <Image
              src={'/media/private-panel.png'}
              width={115}
              height={26}
              alt="private panel"
              className={s.privatePanelLabel}
              priority
            />
          </Link>
          <nav className={s.nav}>
            {HEADER_LINKS.map((el) => (
              <HeaderLink key={el.label} {...el} className={s.linkDesktop} />
            ))}
          </nav>
        </div>
        <div className={cn(s.rightWrapper, { [s.loading]: isAuthLoading })}>
          <NavMobile isOpen={isOpen} onLinkClick={closeHeader} />
          <Wallet />
          <BurgerButton
            isOpen={isOpen}
            onClick={toggleHeader}
            className={s.burgerButton}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
