import React from 'react';
import cn from 'classnames';

import { SOCIALS } from '@app/constants/socials';
import { Icon } from '@app/ui-kit';

import s from './SocialsRow.module.scss';

interface SocialsRowProps {
  className?: string;
  linkClassName?: string;
}

const SocialsRow = ({ className, linkClassName }: SocialsRowProps) => {
  return (
    <div className={cn(s.root, className)}>
      {SOCIALS.map(({ name, icon, href }) => (
        <a
          key={name}
          href={href}
          target="_blank"
          rel="no_referer"
          className={cn(s.link, linkClassName)}
          aria-label={`Visit Wormfare social ${name} group here`}
        >
          <Icon name={icon} />
        </a>
      ))}
    </div>
  );
};

export default SocialsRow;
