import React, { useEffect, useState } from 'react';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';

import { ButtonMain, Modal } from '@app/ui-kit';
import { useAuthContext } from '@app/context/AuthContextProvider';
import { appConfig } from '@app/config';

import s from './WrongNetworkIndicator.module.scss';

const WrongNetworkIndicator = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { isConnected, connector } = useAccount();
  const { isLoading: authLoading } = useAuthContext();
  const requiredChainId = Number(appConfig.requiredChainId);
  const { chain } = useNetwork();
  const { chains, isLoading, switchNetwork } = useSwitchNetwork({
    chainId: requiredChainId,
  });

  const requiredChain = chains.find((el) => el.id === requiredChainId);

  useEffect(() => {
    if (!isLoading && chain && isConnected) {
      if (chain.id !== requiredChainId) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  }, [chain, isConnected, isLoading, requiredChainId]);

  const addNetwork = async () => {
    try {
      const provider = await connector?.getProvider();

      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x' + requiredChain?.id.toString(16),
            chainName: requiredChain?.name,
            rpcUrls: [...requiredChain!.rpcUrls!.default!.http],
            nativeCurrency: {
              name: requiredChain?.nativeCurrency.name,
              symbol: requiredChain?.nativeCurrency.symbol,
              decimals: requiredChain?.nativeCurrency.decimals,
            },
            ...(requiredChain?.blockExplorers?.default && {
              blockExplorerUrls: [requiredChain?.blockExplorers?.default?.url],
            }),
          },
        ],
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleSwitch = async () => {
    await addNetwork();

    try {
      switchNetwork?.(requiredChainId);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      isOpen={isVisible && isConnected && !authLoading}
      onClose={setIsVisible}
      withCloseIcon
    >
      <span className={s.title}>Unsupported Network</span>
      <div className={s.message}>
        Your wallet is connected to a blockchain network that is not supported
        by Wormfare Dashboard.
        <br />
        <br />
        Please switch to the {requiredChain?.name} network.
      </div>
      <ButtonMain
        label={`Switch to ${requiredChain?.name}`}
        onClick={handleSwitch}
      />
    </Modal>
  );
};

export default WrongNetworkIndicator;
