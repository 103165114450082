import React from 'react';
import cn from 'classnames';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import Image from 'next/image';
import { useWeb3Modal } from '@web3modal/wagmi/react';

import { useAuthContext } from '@app/context/AuthContextProvider';

import s from './HeaderLink.module.scss';
import { useDisconnect } from 'wagmi';

export interface HeaderItem {
  label: string;
  href: string;
  external?: boolean;
  image?: string;
  disabled?: boolean;
}

interface HeaderLinkProps extends HeaderItem {
  className?: string;
  onClick?: () => void;
}

const UNPROTECTED_PAGES: string[] = [];

const HeaderLink = ({
  label,
  href,
  external,
  image,
  disabled,
  className,
  onClick,
}: HeaderLinkProps) => {
  const { isAuth } = useAuthContext();
  const { disconnect } = useDisconnect();
  const { open } = useWeb3Modal();
  const pathname = usePathname();

  const isActive =
    (href !== '/' && pathname.includes(href)) ||
    (pathname == '/' && href == '/') ||
    (pathname.includes('/assets/') && href.includes('/assets/'));

  const classNames = cn(s.link, className, {
    [s.active]: isActive,
    [s.disabled]: disabled,
  });

  const linkProps = {
    onClick: () => {
      const isProtectedPage = !UNPROTECTED_PAGES.includes(href);

      if (!isAuth && !external && isProtectedPage) {
        disconnect();
        setTimeout(open, 500);
      }

      onClick?.();
    },
  };

  if (external) {
    return (
      <a
        href={href}
        {...linkProps}
        target="_blank"
        rel="noreferrer"
        className={classNames}
      >
        {label}
      </a>
    );
  }

  return (
    <Link href={href} {...linkProps} className={classNames}>
      {image && (
        <Image
          src={image}
          width={24}
          height={24}
          className={s.image}
          alt="image"
        />
      )}
      <span>{label}</span>
    </Link>
  );
};

export default HeaderLink;
