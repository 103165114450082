'use client';

import { ToastContainer, cssTransition } from 'react-toastify';
import { ReactNode } from 'react';

import Header from '@app/components/Header';
import Footer from '@app/components/Footer';
import WrongNetworkIndicator from '@app/components/WrongNetworkIndicator';

import s from './MainLayout.module.scss';
import VerifyAccountWarning from '../VerifyAccountWarning/VerifyAccountWarning';

interface MainLayoutProps {
  children: ReactNode;
}

const toastTransitionSlideIn = cssTransition({
  enter: 'toastSlideIn',
  exit: 'toastSlideOut',
});

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className={s.root}>
      <Header />
      <main className={s.main}>
        <VerifyAccountWarning />
        {children}
      </main>
      <Footer />
      <ToastContainer
        className={s.toasts}
        icon={false}
        closeButton={false}
        autoClose={2500}
        limit={3}
        transition={toastTransitionSlideIn}
        position="bottom-right"
      />
      <WrongNetworkIndicator />
    </div>
  );
};

export default MainLayout;
