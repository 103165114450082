'use client';

import React, { useState } from 'react';
import cn from 'classnames';
import Image from 'next/image';
import { AnimatePresence, m } from 'framer-motion';

import { useAuthContext } from '@app/context/AuthContextProvider';
import Balance, {
  BalanceType,
  BalanceVariant,
} from '@app/components/Header/Balance';
import { formatHash } from '@app/utils';
import { useUserContext } from '@app/context/UserContextProvider';
import { Icon } from '@app/ui-kit';

import s from './AccountSettingsMenu.module.scss';

interface AccountSettingsMenuProps {
  className?: string;
}

const AccountSettingsMenu: React.FC<AccountSettingsMenuProps> = ({
  className,
}) => {
  const { address, isLoading, balanceWOFR, balanceUSDT, balanceMATIC } =
    useUserContext();
  const { logout } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);

  const copyAddress = () => {
    if (navigator.clipboard && address) {
      navigator.clipboard.writeText(address);
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={cn(s.root, className, { [s.open]: isOpen })}
      onClick={handleToggle}
    >
      <div className={s.userInfo}>
        <div className={s.userInfoWrap}>
          <div className={s.avatarContainer}>
            <Image
              className={s.avatar}
              src="/media/user-avatar.png"
              alt="worm"
              width={44}
              height={44}
            />
          </div>

          <div className={s.address}>
            <span className={s.addressWrap}>{formatHash(address)}</span>
            <Icon name="copy" className={s.copyIcon} onClick={copyAddress} />
          </div>
          <button className="dropdown-toggle" onClick={handleToggle}>
            <Icon
              name="arrowDown"
              className={cn(s.arrow, { [s.up]: isOpen })}
            />
          </button>
        </div>
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <m.div
            className={s.dropdown}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            <div className={s.dropdownWrap}>
              <div className={s.additionalBalances}>
                <Balance
                  type={BalanceType.usdt}
                  variant={BalanceVariant.small}
                  amount={balanceUSDT}
                  isLoading={isLoading}
                />
                <Balance
                  type={BalanceType.matic}
                  variant={BalanceVariant.small}
                  amount={balanceMATIC}
                  isLoading={isLoading}
                />
              </div>
              <div>
                <a className={s.settings} href="/settings">
                  <Icon name="settings" size={16} />
                  Account Settings
                </a>
                <button className={s.disconnect} onClick={logout}>
                  <Icon name="disconnectSmall" size={16} />
                  Disconnect
                </button>
              </div>
            </div>
          </m.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AccountSettingsMenu;
