import React from 'react';
import cn from 'classnames';

import { footerLinks } from '@app/constants/footerLinks';
import SocialsRow from '@app/components/SocialsRow';
import FooterLink from './FooterLink';

import s from './Footer.module.scss';

interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps) => {
  return (
    <footer className={cn(s.root, className)}>
      <div>
        © 2024 Wormfare
        <br />
        Private Panel
      </div>
      <div className={s.main}>
        <svg className={s.logo}>
          <use href="#sprite-footer-logo"></use>
        </svg>
        <div className={s.links}>
          {footerLinks.map((el) => (
            <FooterLink {...el} key={el.label} />
          ))}
          <FooterLink
            iconName={'chat'}
            label={'Need Help?'}
            onClick={() => window.Tawk_API.toggle()}
            as="button"
          />
        </div>
        <SocialsRow />
      </div>
    </footer>
  );
};

export default Footer;
