import React from 'react';
import { m } from 'framer-motion';
import { ButtonMain, ButtonMainStyle } from '@app/ui-kit';

import s from './BurgerButton.module.scss';

interface BurgerButtonProps {
  isOpen: boolean;
  className?: string;
  onClick: () => void;
}

const BurgerButton = ({ isOpen, className, onClick }: BurgerButtonProps) => {
  const animate = isOpen ? 'opened' : 'closed';
  const top = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: 45,
      translateY: 6,
    },
  };
  const mid = {
    closed: {
      scale: 1,
    },
    opened: {
      scale: 0,
    },
  };
  const bottom = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: -45,
      translateY: -6,
    },
  };

  const lineProps = {
    initial: 'closed',
    animate: animate,
    transition: {
      type: 'tween',
      duration: 0.5,
    },
    className: s.line,
  };

  return (
    <ButtonMain
      style={ButtonMainStyle.white}
      className={className}
      onClick={onClick}
    >
      <span className={s.wrapper}>
        <m.span {...lineProps} variants={top} />
        <m.span {...lineProps} variants={mid} />
        <m.span {...lineProps} variants={bottom} />
      </span>
    </ButtonMain>
  );
};

export default BurgerButton;
