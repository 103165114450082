'use client';

import { m } from 'framer-motion';
import React from 'react';
import cn from 'classnames';

import { HEADER_LINKS } from '@app/constants/headerLinks';
import HeaderLink from '@app/components/Header/HeaderLink';

import Balance, { BalanceType } from '../Balance';
import { useAuthContext } from '@app/context/AuthContextProvider';
import { useUserContext } from '@app/context/UserContextProvider';

import s from './NavMobile.module.scss';

interface NavMobileProps {
  isOpen: boolean;
  className?: string;
  onLinkClick: () => void;
}

const menuVariant = {
  closed: {
    scaleY: 0,
    opacity: 0,
    transformOrigin: 'top',
    x: '-50%',
    transition: {
      type: 'tween',
      delay: 0.4,
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
  opened: {
    scaleY: 1,
    opacity: 1,
    transformOrigin: 'top',
    x: '-50%',
    height: 'auto',
    transition: {
      type: 'tween',
      staggerChildren: 0.1,
      delayChildren: 0.25,
    },
  },
};

const linkVariant = {
  closed: {
    opacity: 0,
    y: 50,
    transition: {
      type: 'tween',
      duration: 0.1,
    },
  },
  opened: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
      duration: 0.15,
    },
  },
};

const NavMobile: React.FC<NavMobileProps> = ({
  isOpen,
  className,
  onLinkClick,
}) => {
  const { isAuth, isLoading } = useAuthContext();

  const {
    isLoading: balanceLoading,
    balanceWOFR,
    balanceUSDT,
    balanceMATIC,
  } = useUserContext();

  return (
    <m.div
      animate={isOpen ? 'opened' : 'closed'}
      initial={false}
      variants={menuVariant}
      className={cn(s.root, className)}
    >
      <div className={s.spacerTop} />
      <m.nav className={s.nav}>
        {isAuth && !isLoading && (
          <m.div className={s.balance} variants={linkVariant}>
            <Balance
              isLoading={balanceLoading}
              amount={balanceMATIC}
              type={BalanceType.matic}
            />
            <Balance
              isLoading={balanceLoading}
              amount={balanceUSDT}
              type={BalanceType.usdt}
            />
            <Balance
              isLoading={balanceLoading}
              amount={balanceWOFR}
              type={BalanceType.wofr}
            />
          </m.div>
        )}
        {HEADER_LINKS.map((el) => (
          <m.div
            key={el.label}
            variants={linkVariant}
            className={s.linkWrapper}
          >
            <HeaderLink onClick={onLinkClick} {...el} />
          </m.div>
        ))}
      </m.nav>
    </m.div>
  );
};

export default NavMobile;
