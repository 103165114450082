import React from 'react';
import cn from 'classnames';

import { Icon, IconName, LoadingIndicator } from '@app/ui-kit';
import { numberWithCommas } from '@app/utils';

import s from './Balance.module.scss';
import { useAccount } from 'wagmi';

export enum BalanceType {
  wofr = 'wofr',
  usdt = 'usdt',
  matic = 'matic',
}

export enum BalanceVariant {
  default = 'default',
  small = 'small',
}

interface BalanceProps {
  type: BalanceType;
  amount: string | undefined;
  isLoading?: boolean;
  variant?: BalanceVariant;
  className?: string;
}

const Balance = ({
  type,
  amount,
  isLoading,
  variant = BalanceVariant.default,
  className,
}: BalanceProps) => {
  const { isConnected } = useAccount();
  let iconName: IconName;
  let currency;

  switch (type) {
    case BalanceType.usdt:
      iconName = 'usdt';
      currency = 'USDT';
      break;
    case BalanceType.wofr:
      iconName = 'wofr';
      currency = 'WOFR';
      break;
    case BalanceType.matic:
      iconName = 'matic';
      currency = 'MATIC';
      break;
  }

  return (
    <div
      className={cn(
        s.root,
        s[variant],
        {
          [s.red]:
            type === BalanceType.matic &&
            amount &&
            +amount < 0.1 &&
            isConnected,
        },
        className,
      )}
    >
      <Icon name={iconName} className={s.icon} />
      <div className={s.wrapper}>
        {variant !== BalanceVariant.small && (
          <div className={s.topLabel}>{currency} Balance</div>
        )}
        <div className={s.amount}>
          <LoadingIndicator
            height="12px"
            valueToObserve={isLoading || !isConnected}
          >
            {numberWithCommas(amount)}
          </LoadingIndicator>
          <span className={s.currency}>{currency}</span>
        </div>
      </div>
    </div>
  );
};

export default Balance;
