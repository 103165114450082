import { useAuthContext } from '@app/context/AuthContextProvider';

import s from './VerifyAccountWarning.module.scss';

const VerifyAccountWarning = () => {
  const { isAuth, isLoading, isVerified, openVerifyModal } = useAuthContext();

  if ((isAuth && isVerified) || isLoading || !isAuth) {
    return null;
  }

  return (
    <div className={s.root}>
      Hi sweetie! To keep enjoying our services, it&apos;s important that you{' '}
      <button onClick={() => openVerifyModal()}>VERIFY</button> your account.
    </div>
  );
};

export default VerifyAccountWarning;
